import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Grid, Stack } from '@mui/material';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import '../fonts.css';
import { languageOptions } from './reusableComponents/LanguageList';
import IMRComponent from './reusableComponents/IMRComponent';

const CustomFooter = styled.footer`
  background-color: red;
  padding: 24px;
`;
const CustomLogo = styled.div`
  width: ${(props) => (props.isMobile ? '86px' : '107.38px')};
  height: ${(props) => (props.isMobile ? '32px' : '40px')};
  align-self: flex-start;
  img {
    max-width: 100%;
    height: auto;
  }
  marginbottom: 0;
`;
const CustomFontText = styled.div`
  font-family: 'JohnsonText-Regular', sans-serif;
  color: white;
  font-weight: 400;
  margin-top: 10px;
  line-height: ${(props) => props.isMobile && '24px'};
  max-width: ${(props) => props.isMobile && '327px'};
  @media (min-width: 992px) {
    max-width: 590px;
  }
  @media (min-width: 1300px) {
    max-width: 630px;
  }
  @media (min-width: 1400px) {
    max-width: 710px;
  }
`;
const CustomLinks = styled.div`
margin-top:10px;
font-family: 'JohnsonText-Regular', sans-serif;
font-weight:400;
a{
    text-decoration:none;
    color:white; !important;
}
.separator{
    color: white;
}
`;

function Footer({ page, showIMR=true }) {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    let touLanguage = localStorage.getItem('tou-language');
    page === 'tou' && i18n.changeLanguage(touLanguage);

    console.log(document.body.style.zoom);
    //document.body.style.zoom = "100%";
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
      setWindowWidth(window.innerWidth);
    };
    //This line adds an event listener to the resize event of the window
    window.addEventListener('resize', handleResize);
    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  
  const selectedLanguage = i18n.language;
  const IMRTextGlobal = languageOptions.filter((language) => language.code === selectedLanguage)[0]?.IMRContent;
  const IMRTextTOU = languageOptions.filter((language) => language.code === selectedLanguage)[0]?.IMRContentTOU;
  const IMRText = page === 'tou' ? IMRTextTOU : IMRTextGlobal;

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const currentYear = new Date().getFullYear();
  return isMobile ? (
    <div>
      {IMRText && showIMR && <IMRComponent text={IMRText} />}
      <CustomFooter isMobile className="mob-footer" style={{ maxWidth: `${windowWidth}` }}>
        <CustomLogo isMobile>
          <Link to={'/'}>
            <img
              src="logos/JNJ_Logo_Stacked_White_RGB.png"
              width={isMobile ? '86px' : '107px'}
              height={isMobile ? '32px' : '40px'}
              onClick={scrollToTop}
            />
          </Link>
        </CustomLogo>
        <CustomFontText isMobile>{t('footer.copyright', { currentYear })}</CustomFontText>
        <CustomLinks isMobile>
          <Grid container direction="row" sx={{ justifyContent: 'space-between' }}>
            <Grid item>
              <Link to="/privacy-policy" onClick={scrollToTop}>
                {t('footer.privacyPolicy')}
              </Link>
            </Grid>
            <Grid item>
              <span className="separator">|</span>
            </Grid>
            <Grid item>
              <Link to="/terms-of-use" onClick={scrollToTop}>
                {t('footer.termsOfUse')}
              </Link>
            </Grid>
            <Grid item>
              <span className="separator">|</span>
            </Grid>
            <Grid item>
              <Link to="https://www.jnj.com/contact-us" onClick={scrollToTop}>
                {t('footer.contactUs')}
              </Link>
            </Grid>
          </Grid>
        </CustomLinks>
      </CustomFooter>
    </div>
  ) : (
    <div>
      {IMRText && showIMR && <IMRComponent text={IMRText} />}
      <CustomFooter>
        <Stack direction={'row'} alignItems="center" justifyContent="space-between">
          <CustomLogo>
            <Link to={'/'}>
              <img id="footerLogo" src="logos/JNJ_Logo_Stacked_White_RGB.png" onClick={scrollToTop} />
            </Link>
          </CustomLogo>
          <CustomFontText className="footer-text">{t('footer.copyright', { currentYear })}</CustomFontText>
          <CustomLinks className="footer-links">
            <Grid container direction="row" spacing={1} alignItems="center">
              <Grid item>
                <Link to="/privacy-policy" onClick={scrollToTop}>
                  {t('footer.privacyPolicy')}
                </Link>
              </Grid>
              <Grid item>
                <span className="separator">|</span>
              </Grid>
              <Grid item>
                <Link to="/terms-of-use" onClick={scrollToTop}>
                  {t('footer.termsOfUse')}
                </Link>
              </Grid>
              <Grid item>
                <span className="separator">|</span>
              </Grid>
              <Grid item>
                <Link to="https://www.jnj.com/contact-us" target="_blank" onClick={scrollToTop}>
                  {t('footer.contactUs')}
                </Link>
              </Grid>
            </Grid>
          </CustomLinks>
        </Stack>
      </CustomFooter>
    </div>
  );
}
export default Footer;
